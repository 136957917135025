.main {
  padding: 0 18px;
  margin-left: 284px;
  box-sizing: border-box;
}

@media (min-width: 1441px) {
  .main {
    margin-top: 10px;
  }
}

@media (max-width: 450px) {
  .main {
    width: 100%;
    margin-left: 0;
  }
}