.calendar {
  width: 360px;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}

.calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.calendar__title {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.calendar__container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.calendar__button {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #e9e9e9;
  cursor: pointer;
}

.calendar__month {
  margin: 0;
  width: 120px;
  text-align: center;
  height: fit-content;
  font-weight: 400;
  font-size: 16px;
  color: #000;
}

.calendar__button-image {
  width: 12.5px;
  height: 10px;
}

.calendar__weekdays {
  width: 315px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
}

.calendar__weekday {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 200%;
  letter-spacing: -0.08em;
  text-align: center;
  color: #000;
}

.calendar__days {
  width: 315px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(7, 40px);
  gap: 5px;
}

.calendar__day {
  width: 40px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: -0.07em;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  box-sizing: border-box;
  transition: all .2s linear;
}

.calendar__day_disable {
  color: #d9dbe1;
}

.calendar__day_type_now {
  background-color: #317bff;
  color: #fff;
}

.calendar__day_type_now:hover {
  background-color: #317bff;
}

.calendar__day_active {
  border: 1px solid #317bff;
}

.calendar__day:hover {
  background-color: #F0F0F0;
}

@media (max-width: 450px) {
  .calendar {
    width: 100%;
  }

  .calendar__weekdays {
    width: 100%;
  }

  .calendar__days {
    width: 100%;
    grid-template-columns: repeat(7, 1fr);
  }

  .calendar__day {
    width: 35px;
    height: 35px;
  }

}