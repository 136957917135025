.business {
  margin: auto;
  margin-top: 60px;
  width: 1320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}

.business__header {
  width: 1096px;
  display: flex;
  justify-content: space-between;
}

.business__title {
  margin: 0;
  width: 1096px;
  font-weight: 500;
  font-size: 36px;
  color: #000;
}

.business__button {
  width: fit-content;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 14px;
  border: 0;
  font-weight: 500;
  font-size: 20px;
  color: #317bff;
  text-wrap: nowrap;
  cursor: pointer;
  text-decoration: none;
}

.statistic {
  margin-top: 30px;
  max-width: 1096px;
  max-height: 588px;
  display: flex;
  gap: 20px;
}

.statistic__container {
  width: 315px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.statistic__card {
  width: 100%;
  height: 100%;
  padding: 20px 19px 20px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 10px;
}

.statistic__title {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  color: #000;
}

.statistic__subtitle {
  margin: 0;
  font-weight: 500;
  font-size: 48px;
  color: #317bff;
}

.statistic__card-container {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.statistic__day {
  margin: 0;
  height: fit-content;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.statistic__image {
  width: 426px;
  border-radius: 20px;
}

.b-cards {
  
}

.b-cards__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.b-card {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.b-card__title {
  margin: 0;
  height: 55px;
  font-weight: 500;
  font-size: 24px;
  color: #000;
}

.b-card__image {
  width: 100%;
}

.b-card__container {
  display: flex;
  gap: 20px;
  justify-content: end;
  align-items: end;
}

.b-card__price {
  margin: 0;
  font-weight: 500;
  font-size: 48px;
  color: #317bff;
}

.b-card__month {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.advantages {
  margin-bottom: 60px;
  width: 1096px;
  display: flex;
  gap: 20px;
}

.advantages__column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 315px;
}

.advantages__item {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  gap: 20px;
}

.advantages__image {
  width: 30px;
  height: 30px;
}

.advantages__title {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.advantages__mockup {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 450px) {
  .business {
    margin: 25px 0 20px 0;
    padding: 0 18px;
    width: 100%;
    box-sizing: border-box;
    gap: 30px;
  }

  .statistic {
    margin-top: 0;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    max-height: fit-content;
  }

  .statistic__container {
    width: 100%;
  }

  .statistic__image {
    width: 100%;
  }

  .b-cards {
    position: relative;
    height: 370px;
    width: 100%;
  }

  .b-cards__container {
    padding: 0 18px;
    box-sizing: border-box;
    width: 100vw;
    position: absolute;
    left: -18px;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 20px;
  }

  .b-cards__container::-webkit-scrollbar {
    display: none;
  }

  .b-card {
    min-width: 210px;
    box-sizing: border-box;
    height: fit-content;
  }

  .b-card__title {
    height: 37px;
    font-size: 16px;
  }

  .b-card__price {
    font-size: 28px ;
  }

  .b-card__month {
    font-size: 14px;
  }

  .advantages {
    width: 100%;
    flex-direction: column;
  }

  
}