.slider {
  position: relative;
  min-width: 362px;
  max-width: 362px;
  height: 554px;
  margin: auto;
  overflow: hidden;
  border-radius: 20px;
  display: flex;
  justify-content: center;
}

.slider__ad {
  position: relative;
  display: flex;
  height: 100%;
  transition: transform 1s ease-in-out;
}

.slider__link {
  max-height: 554px;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  margin-right: 20px;
}

.slider__image {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.slider__ad-title {
  margin: 0;
  position: absolute;
  bottom: 11px;
  right: 11px;
  background-color: white;
  text-decoration: underline;
  font-weight: 400;
  font-size: 13px;
  line-height: 77%;
  letter-spacing: 0.03em;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #0a1b39;
  border-radius: 6px;
  padding: 6px;
  backdrop-filter: blur(5px);
}

.slider__buttons {
  position: absolute;
  bottom: 10px;
  display: flex;
  gap: 5px;
}

.slider__button {
  padding: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  background: #fff;
  border: none;
  cursor: pointer;
  transition: all .4s linear;
}

.slider__button--active {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  background: #676e85;
}

@media (max-width: 450px) {
  .slider {
    order: 0;
    max-width: 100%;
    min-width: 100%;
    height: fit-content;
  }

  .slider__image {
    object-fit: cover;
    object-position: left;
  }
}