@keyframes popupOpen {
  0% {
    z-index: -2;
    opacity: 0;
    visibility: hidden;
  } 

  25% {
    z-index: 3;
  }

  100% {
    z-index: 3;
    opacity: 1;
    visibility: visible;
  }
}

@keyframes popupClose {
  0% {
    z-index: 3;
    opacity: 1;
    visibility: visible;
  }

  75% {
    z-index: 3;
  }

  100% {
    z-index: -2;
    opacity: 0;
    visibility: hidden;
  }
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  z-index: -2;
  visibility: hidden;
}

.popup_active {
  animation: popupOpen 0.5s forwards;
}

.popup_closed {
  animation: popupClose 0.8s forwards;
}

.popup__container {
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  width: 692px;
  background-color: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  transition: all .2s linear;
}

.popup__close-button {
  height: 20px;
  width: 20px;
  position: absolute;
  top: -20px;
  right: -20px;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.popup__close-button-image {
  width: 100%;
  height: 100%;
}

.popup__header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.popup__title {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  color: #000;
}

.popup__columns {
  display: flex;
  gap: 30px;
  width: 100%;
}

.popup__column {
  width: calc((100% / 2) - 15px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup__column-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup__subtitle {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  color: #000;
  width: 100%;
}

.popup__text {
  margin: 0;
  font-weight: 500;
  font-size: 11px;
  text-align: justify;
  display: flex;
  align-items: center;
  color: #858585;
  display: flex;
  width: 100%;
  justify-content: space-between;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup__text_type_small {
  display: flex;
}

.popup__text_type_copy {
  cursor: pointer;
  display: flex;
}

.popup__tags {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 50px;
}

.popup__tag {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  font-size: 10px;
  color: #000;
  background-color: #e4e4e4;
  border-radius: 10px;
  height: 20px;
  width: fit-content;
  box-sizing: border-box;
}

.popup__buttons {
  width: fit-content;
  display: flex;
  background-color: #e4e4e4;
  border-radius: 14px;
  padding: 2px;
}

.popup__button {
  padding: 4px 8px;
  margin: 0;  
  font-weight: 400;
  font-size: 10px;
  letter-spacing: -0.01em;
  color: #000;
  cursor: pointer;
  transition: all .2s linear;
}

.popup__button_active {
  background-color: white;
  border-radius: 12px;
}

.popup__slider {
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 224px;
  display: flex;
}

.popup__slide {
  position: absolute;
  width: 100%;
  height: 224px;
  top: 0;
  left: 100%;
  opacity: 0;
  display: flex;
  transition: all 0.3s ease-in-out;
  
}

.popup__slide_active {
  left: 0;
  opacity: 1;
}

.popup__image {
  width: 100%;
  border-radius: 14px;
  height: 224px;
  object-fit: cover;
  object-position: center;
}

.popup__map {
  width: 100%;
  height: 224px;
  border: 0;
  border-radius: 14px;
  overflow: hidden;
  border: 1px solid #e4e4e4;
}

.popup__flag {
  width: 30px;
  height: 20px;
  box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.popup__date {
  margin: 0;
  padding: 4px 10px;
  border-radius: 14px;
  background-color: #e4e4e4;
  font-weight: 500;
  font-size: 12px;
  color: #000;
  width: fit-content;
}

.popup__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.popup__footer-container {
  display: flex;
  justify-content: end;
  gap: 10px;
  width: 100%;
}

.popup__price {
  padding-right: 10px;
  display: flex;
  gap: 10px;
  background-color: #e4e4e4;
  border-radius: 14px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #010101;
}

.popup__price-button {
  text-decoration: none;
}

.shops {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #e4e4e4;
  border-radius: 20px;
  width: 100%;
  height: 224px;
  box-sizing: border-box;
}

.shop {
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b6b6b6;
}

.shop:last-of-type {
  border: 0;
}

.shop__container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.shop__title {
  margin: 0;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: -0.01em;
  color: #000;
}

.shop__image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.shop__link {
  padding: 6px 10px;
  margin: 0;
  text-decoration: none;
  font-weight: 400;
  font-size: 10px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #000;
  background-color: #fff;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.ymaps-2-1-79-balloon__content {
  display: none;
}

.ymaps-2-1-79-panel-pane {
  display: none;
}

.ymaps-2-1-79-searchbox {
  display: none;
}

@media (max-width: 450px) {
  .popup__container {
    width: 100%;
    height: 100%;
    border-radius: 0;
    overflow-y: auto;
  }

  .popup__header {
    flex-direction: column;
    gap: 20px;
    align-items: start;
  }

  .popup__columns {
    flex-direction: column;
    gap: 20px;
  }

  .popup__column {
    width: 100%;
  }

  .popup__close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 12px;
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5); /* Прозрачный белый фон */
    backdrop-filter: blur(10px); /* Размытие фона за элементом */
  }

  .popup__close-button-image {
    width: 18px;
    height: 18px;
    filter: brightness(0) saturate(100%);
  }

  .popup__title {
    order: 2;
    font-size: 24px;
    max-width: 100%;
  }

  .popup__subtitle {
    font-size: 18px;
  }

  .popup__text {
    font-size: 16px;
  }

  .popup__text-image {
    width: 20px;
  }

  .popup__tag {
    padding: 14px 16px;
    border-radius: 20px;
    font-size: 14px;
  }

  .popup__buttons {
    align-self: flex-end;
    border-radius: 20px;
  }

  .popup__slider {
    height: 355px;
  }

  .popup__slide {
    height: 100%;
  }

  .popup__image {
    border-radius: 20px;
    height: 100%;
  }

  .shops {
    height: 100%;
    gap: 25px;
  }

  .shop__image {
    width: 50px;
    height: 50px;
  }

  .shop__title {
    font-size: 16px;
  }

  .shop__link {
    padding: 14px;
    font-size: 14px;
    border-radius: 20px;
  }

  .shop__link-image {
    width: 18px;
    height: 18px;
  }

  .popup__map {
    height: 100%;
  }

  .popup__button {
    padding: 7px 8px;
    border-radius: 20px;
    font-size: 14px;
  }

  .popup__footer {
    flex-direction: column;
    gap: 20px;
    align-items: center
  }

  .popup__footer-container {
    flex-direction: column;
    justify-content: space-between;
  }
  
  .popup__price {
    width: fit-content;
    border-radius: 20px;
    padding: 0 12px 0 0;
  }

  .popup__price-button {
    padding: 7px 10px;
    height: 34px;
    font-size: 14px;
    border-radius: 20px;
  }
}