.about {
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  width: 1096px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.about__card {
  width: 100%;
  padding: 30px;
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.about__text {
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  color: #000;
}

.about__text_type_big {
  font-size: 24px;
}

.about__text_type_blue {
  color: #317bff;
  text-decoration: none;
}

.about__container {
  display: flex;
  gap: 40px;
}

.about__column {
  min-width: calc(50% - 20px);
  max-width: calc(50% - 20px);
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.about__title {
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  color: #000;
}

.about__points {
  width: 100%;
  display: flex;
  gap: 30px;
}

.about__point {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about__circle {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #317bff;
  border-radius: 50%;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #317bff;
  box-sizing: border-box;
}

.about__line {
  width: 0;
  height: 120px;
  border: 1px solid #317bff;
}

.about__line_type_small {
  height: 80px;
}

.about__point-title {
  margin: 20px 0 0;
  font-weight: 400;
  font-size: 20px;
  color: #000;
}

@media (max-width: 450px) {
  .about {
    margin-top: 25px;
    padding: 0 18px;
    width: 100%;
    box-sizing: border-box;
    gap: 30px;
  }

  .about__text {
    font-size: 16px;
  }

  .about__text_type_big {
    font-size: 18px;
  }

  .about__title {
    font-size: 22px;
  }

  .about__container {
    flex-direction: column;
    width: 100%;
  }

  .about__column {
    min-width: 100%;
    max-width: 100%;
  }

  .business__header {
    width: 100%;
  }

  .about__card {
    padding: 20px;
  }

  .business__button {
    display: none;
  }

  .business__title {
    width: 100%;
    font-size: 26px;
  }

  .about__point-title {
    font-size: 16px;
  }
}