.wish-nav {
  margin: 20px 0;
  height: 175px;
  display: flex;
  gap: 20px;
}

.wish-nav__overlay {
  display: flex;
  gap: 20px;
}

.wish-nav__container {
  height: 100%;
  width: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.wish-nav__button {
  padding: 8px 15px 8px 20px;
  border: 0;
  height: 45px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  box-sizing: border-box;
  transition: background-color .2s linear;
}

.wish-nav__button-image {
  padding: 5px;
  border-radius: 50%;
  transition: all .2s linear;
}

.wish-nav__button:hover .wish-nav__button-image{
  background-color: #F0F0F0;
}

.counter {
  padding: 20px;
  width: 220px;
  height: fit-content;
  background-color: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.counter__image {
  padding: 20px;
  width: 70px;
  height: 70px;
  border: 1px solid #317bff;
  border-radius: 50%;
  overflow: visible;
  display: flex;
  box-sizing: border-box;
  filter: brightness(0) saturate(100%) invert(39%) sepia(81%) saturate(2780%) hue-rotate(207deg) brightness(101%) contrast(101%);
}

.counter__container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.counter__title {
  margin: 0;
  width: fit-content;
  height: fit-content;
  font-weight: 500;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.75);
}

.counter__subtitle {
  margin: 0;
  width: fit-content;
  height: fit-content;
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.lists-small {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: fit-content;
}

.list-small {
  padding: 4px 10px;
  margin: 0;
  border-radius: 10px;
  width: fit-content;
  height: 20px;
  justify-self: start;
  background-color: #e4e4e4;
  font-weight: 400;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 10px;
  color: #000;
  transition: all .2s linear;
}

.list-small_type_add {
  padding: 4px;
}

.list-small_active {
  background-color: #317bff;
  color: #fff;
  cursor: default;
}

.list-small__image {
  width: 12px;
  height: 12px;
}

.abs {
  padding: 20px 15px 15px 20px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  width: 170px;
  height: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: end;
  cursor: pointer;
  opacity: 1;
}

.abs:hover  .abs__image{
  background-color: #F0F0F0
}

.abs__title {
  margin: 0;
  width: 100px;
  height: 100%;
  font-weight: 500;
  font-size: 12px;
  color: #000;
}

.abs__image {
  padding: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: visible;
  transition: all .2s linear;
}

@media (max-width: 450px) {
  .wish-nav {
    position: relative;
    height: 186px;
  }

  .wish-nav__overlay {
    padding: 0 18px;
    box-sizing: border-box;
    width: 100vw;
    position: absolute;
    left: -18px;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 20px;
  }

  .wish-nav__overlay::-webkit-scrollbar {
    display: none;
  }

  .wish-nav__container:first-of-type {
    flex-direction: row;
    order: -2;
  }

  .counter {
    width: fit-content;
    height: 186px;
  }

  .counter__image-container {
    border: 1px solid #317bff;
    border-radius: 50%;
  }

  .counter__image {
    border: 0;
    size-adjust: 20px;
    border-radius: 0;
  }

  .wish-nav__button {
    padding: 8px 15px;
    width: fit-content;
    height: 186px;
  }

  .abs {
    height: 83px;
  }

  .abs__image {
    border-radius: 0;
  }

  .abs:hover .abs__image{
    background-color: transparent;
  }

  .list-small {
    padding: 14px 16px;
    font-size: 14px;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  
}