.tips {
  display: flex;
  flex-direction: column;
}

.tips__container {
  margin-top: 24px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

@media (max-width: 450px) {

  .tips {
    width: 100%;
  }
  .tips__container {
    flex-direction: column;
    width: 100%;
  }
}