.profile  {
  padding: 0 18px;
  margin-left: 284px;
  width: 100%;
}

@media (max-width: 450px) {
  .profile {
    margin-left: 0;
    box-sizing: border-box;
  }
}