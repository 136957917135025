.social {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.social__title {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 5px;
}

.social__title-counter {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  color: #317bff;
}

.persons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.person {
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
}

.person__image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.person__image_type_brand {
  border: 1px solid #f0f0f0;
}

.person__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.person__title {
  margin: 0;
  width: 200px;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.person__title:hover {
  text-decoration: underline;
}

.person__link {
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  color: #317bff;
}

.person__link:hover {
  text-decoration: underline;
}

@media (max-width: 450px) {
  .persons {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .person__image {
    width: 90px;
    height: 90px;
  }

  .person__container {
    width: 170px;
  }
}