.welcome {
  margin: 0;
  max-width: 492px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.welcome__container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.welcome__title {
  margin: 0;
  font-weight: 600;
  font-size: 40px;
  color: #fff;
}

.welcome__subtitle {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  color: #fff;
}

.welcome__link {
  display: flex;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
  color: #fff;
  text-decoration: none;
}

.welcome__image {
  margin-top: 32px;
  width: 100%;
}