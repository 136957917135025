.brand-card {
  display: flex;
  background-color: white;
  border-radius: 20px;
  position: relative;
}

.brand-card_type_large {
  grid-column: span 2;
}

.brand-card__image {
  margin: 16px;
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: center;
  border-radius: 50%;
  border: 1px solid #F0F0F0;
}

.brand-card__title {
  width: 0;
  height: 0;
  margin: 0;
  text-wrap: nowrap;
  position: absolute;
  right: 20px;
  bottom: 40px;
  transform: rotate(-90deg);
  transform-origin: left center; /* Устанавливает точку поворота */
  font-weight: 400;
  font-size: 10px;
  line-height: 190%;
  text-align: center;
  color: rgba(141, 141, 141, 0.47);
}

@media (max-width: 450px) {
  .brand-card {
    min-width: 173px;
    height: 255px;
    
  }

  .brand-card_type_large {
    min-width: 324px;
  }
}