@keyframes openError {
  from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
 }

 .root {
  width: 100%;
  min-height: 100vh;
  background-color: #F0F0F0;
 }

.page {
  min-width: 320px;
  max-width: 1440px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  display: flex;
  font-family: "Inter", Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #000000;
  

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.page_reduced {
  overflow: hidden;
}

.page_type_max {
  max-width: 100vw;
}

/* Inputs */

.inputs-container {
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.inputs-container_type_big {
  height: 124px;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  box-sizing: border-box;
  width: 100%;
  background-color: transparent;
  border: 1px solid #C2C2C2;
  border-radius: 14px;
  overflow: hidden;
}

.input-container_type_big {
  height: 105px;
}

.input-container_type_settings {
  margin: 0 23.5px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.input {
  padding: 15px 16px;
  border: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #09101d;
  box-sizing: border-box;
  background-color: white;
  transition: all 0.2s linear;
  outline: none;
}

.input_type_big {
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;
  resize: none;
  box-sizing: border-box;
}

.input_type_path {
  padding-left: 0;
}

.input_type_list {
  cursor: pointer;
}

.input-container:focus-within {
  border: 1px solid #005cff;
}

.inputs-container:focus-within .input__title {
  color: #005cff;
}

.inputs-container:focus-within .input-error_active {
  display: none;
}

.inputs-container:focus-within .input__image  {
  filter: brightness(0) saturate(100%) invert(21%) sepia(87%) saturate(2981%) hue-rotate(216deg) brightness(101%) contrast(113%);
}

.inputs-container:focus-within .input__image  {
  filter: brightness(0) saturate(100%) invert(21%) sepia(87%) saturate(2981%) hue-rotate(216deg) brightness(101%) contrast(113%);
}

.inputs-container:active-within .input__image  {
  filter: brightness(0) saturate(100%) invert(21%) sepia(87%) saturate(2981%) hue-rotate(216deg) brightness(101%) contrast(113%);
}

.input-container_type_error {
  border: 1px solid #da1414;
}

.input__image {
  padding: 15px 16px;
  object-fit: contain;
  height: 17px;
  width: 17px;
  cursor: pointer;
}

.input__image_type_error {
  filter: brightness(0) saturate(100%) invert(24%) sepia(96%) saturate(3825%) hue-rotate(351deg) brightness(85%) contrast(103%);
}

.input__title {
  background-color: white;
  padding: 5px 10px;
  margin: 0;
  position: absolute;
  border-radius: 4px;
  top: -9px;
  left: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 72%;
  color: #9d9aa1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s linear;
}

.input__title_type_error {
  color: #da1414;
}

.input__title_active {
  opacity: 1;
  visibility: visible;
}

.input-error {
  display: none;
  overflow: hidden;
  align-items: start;
  gap: 5px;
  min-height: 15px;
  padding-left: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #da1414;
  transition: all .2s linear;
}

.input-error_active {
  display: flex;
  animation: openError 0.3s;
}


/* Новый Input */

.input-path {
  padding: 15px 0 15px 16px;
  margin: 0;
  color: #09101d;
  font-weight: 500;
  font-size: 14px;
  color: #b1b1b1;
  cursor: pointer;
}

.input-container__image {
  width: 100%;
  height: 100%;
  position: relative;
  border: 0;
  border-radius: 50%;
  text-align: center;
  transition: background-color 0.3s;
}

.input-container__image:hover .input__overlay{
  opacity: 1;
}

.input__overlay {
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 50%;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
  color: #cacaca;
  cursor: pointer;
  transition: all .2s linear;
}

.input__overlay_active {
  opacity: 1;
}

.input__overlay-image {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(42deg) brightness(104%) contrast(101%);
}

.input__overlay-title {
  width: 70%;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #fff; 
}

.input-placeholder {
  color: #999;
}

.input-file {
  padding: 0;
  opacity: 0;
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

/* Checkbox */

.checkbox {
  width: fit-content;
  display: inline-flex;
  align-items: start;
  gap: 12px;
  font-weight: 400;
  font-size: 12px;
  color: #424242;
  cursor: pointer;
  transition: all .2s linear;
}

.checkbox__input {
  display: none;
}

.checkbox__button {
  position: relative;
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  background: transparent;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.2s linear;
}

.checkbox__button::after {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  display: block;
  background: #fff;
  box-sizing: border-box;
  border-radius: 50%;
  transform: scale(0);
  background-image: url(../../images/Checkbox/checkbox-icon.svg);
  background-position: center;
  transition: transform 0.2s linear, background-color 0.2s linear, border-radius 0.3s linear;
}

.checkbox__input:checked + .checkbox__button::after {
  transform: scale(1);
  background-color: #317bff;
  border: 1px solid #317bff;
  border-radius: 0;
}

.checkbox__policy {
  font-weight: 700;
  color: #317bff;
}

/* Buttons */

.button {
  border-radius: 14px;
  padding: 14px 20px;
  height: 45px;
  border: 0;
  font-weight: 500;
  background-color: #317bff;
  font-size: 14px;
  color: #fff;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  transition: all 0.2s linear;
}

.button_type_small {
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  height: 35px;
  font-size: 12px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #fff;
}

.button_type_gray {
  background-color: #e4e4e4;
  color: #000 !important;
}

.button_type_disabled {
  background-color: #F1F1F1;
  color: #919191;
  cursor:default;
}

.button__image {
  width: 12px;
  height: 12px;
}

/* Caption */

.caption {
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.caption__title {
  margin: 0;
  height: 41px;
  font-weight: 500;
  font-size: 24px;
  color: #000;
  display: flex;
  align-items: center;
}

/* Nav */

.nav {
  padding: 20px;
  width: 360px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}

.nav_type_cursor {
  cursor: default;
}

.nav_type_wish {
  width: 310px;
}

.nav_type_list {
  flex-direction: column;
  justify-content: start;
  gap: 20px;
}

.nav__title {
  width: 100%;
  height: max-content;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav__title-counter {
  padding-bottom: 1px;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #317bff;
  align-self: flex-end;
}

/* sidebar-button  */

.sidebar-button {
  display: none;
}

@media (max-width: 450px) {
  .caption {
    align-items: start;
    flex-direction: column;
    gap: 20px;
  }

  .caption_header {
    padding: 16px 18px;
    left: 0;
    flex-direction: row;
    justify-content: space-between;
    z-index: 2;
    box-sizing: border-box;
  }

  .button {
    border-radius: 20px;
    padding: 14px 20px;
    height: 45px;
    font-size: 14px;
  }
  
  .button_type_small {
    padding: 14px;
    font-size: 18px;
    height: 50px;
  }

  .button_type_gray {
    padding: 12px;
    font-size: 16px;
  }

  .button__image {
    width: 18px;
    height: 18px;
  }

  .sidebar-button {
    position: fixed;
    padding: 10px;
    display: flex;
    top: 15px;
    right: 18px;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 0;
    background-color: transparent;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    z-index: 3;
  }

  .sidebar-button_static {
    position:relative;
    top: 0;
    right: 0;
  }

  .sidebar-button__image {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    margin: 5px;
  }

  .nav {
    width: 100%;
  }

  .nav_type_wish {
    min-width: 250px;
    height: 186px;
    order: 0;
  }
  
  .nav_type_list {
    min-width: 330px;
    height: 186px;
    order: -1;
  }

  .input {
    font-size: 16px;
  }
}