.settings {
  padding: 0 20px;
  margin-left: 284px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.settings__form {
  padding: 20px;
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-radius: 20px;
  box-sizing: border-box;
}

.settings__container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.settings__title {
  padding-bottom: 20px;
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  color: #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.settings__inputs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 13px 20px;
}

.settings__inputs_type_columns {
  grid-template-columns: repeat(2, 1fr);
  height: fit-content;
}

.settings__footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.settings__button {
  padding: 15px;
  background-color: #317bff;
  border-radius: 14px;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.settings__button_type_logout {
  background-color: transparent;
  color: #317bff;
  border: 1px solid #317bff;
}

.dropdown-container {
  z-index: 3;
  width: 100%;
  overflow: hidden;
  border-radius: 14px;
  position: absolute;
  top: 55px;
  left: 0;
}

.dropdown-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 0;
  overflow: hidden;
  background-color: #fff;
  border-radius: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all .2s linear;
}

.dropdown-menu_overflow {
  overflow-y: scroll;
}

/* Стилизация скролл-бара */
.dropdown-menu::-webkit-scrollbar {
  width: 10px; /* Ширина скролл-бара */
}

.dropdown-menu::-webkit-scrollbar-track {
  background: transparent; /* цвет дорожки */
  border-radius: 10px; /* закругление углов */
}

.dropdown-menu::-webkit-scrollbar-thumb {
  background: #888; /* цвет ползунка */
  border-radius: 10px; /* закругление углов */
}

.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #555; /* цвет ползунка при наведении */
}

.dropdown-menu_active {
  height: 142px;
  border: 1px solid #317bff;
}

.dropdown-menu__item {
  padding: 15px 16px;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  transition: all .2s linear;
}

.dropdown-menu__item:hover {
  background-color: #f0f0f0;
}


@media (min-width: 1441px) {
  .settings {
    margin-top: 11px;
  }
}

@media (max-width: 450px) {
  .settings {
    margin-left: 0;
    box-sizing: border-box;
  }

  .settings__form {
  }

  .settings__container {
    flex-direction: column;
  }

  .settings__inputs {
    display: flex;
    flex-direction: column;
  }

  .settings__button {
    width: 100%;
  }

  .settings__footer {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
}