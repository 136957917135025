/* @keyframes cardOpen {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
} */

.search {
  position: relative;
  padding: 12px 20px;
  max-width: 363px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: white;
  box-sizing: border-box;
  transition: all .4s linear;
}

.search_active {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
}

.search__button {
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  display: flex;
  cursor:  pointer;
}

.search__button-image {
  width: 12px;
  height: 12px;
}

.search__button-image_active {
  filter: brightness(0) saturate(100%) invert(41%) sepia(14%) saturate(4712%) hue-rotate(193deg) brightness(105%) contrast(106%);
}

.search__input {
  padding: 0;
  border: 0;
  background-color: transparent;
  font-weight: 400;
  font-size: 12px;
  color: #09101d;
  width: 235px;
  height: 16px;
  outline: none;
}

.search__line {
  margin: 0;
  height: 16px;
  width: 1px;
  background-color: #d8d8d8;;
  border: 0; 
}

.fillter {
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  width: 363px;
  padding: 20px;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  transition: all .4s linear;
}

.fillter_active {
  opacity: 1;
  visibility: visible;
  display: flex;
  flex-direction: column;
}

.fillter__title {
  margin: 0 0 10px;
  font-weight: 500;
  font-size: 12px;
  color: #000;
}

.sections {
  margin-bottom: 20px;
  display: flex;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.section {
  border: 1px solid #d8d8d8;
  border-radius: 14px;
  padding: 12px;
  background-color: transparent;
  display: flex;
  gap: 6px;
  flex-direction: column;
  align-items: start;
  width: 94px;
  height: 58px;
  cursor: pointer;
  transition: all .2s linear;
}

.section__active {
  border: 1px solid #317bff;
}

.section__image {
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(55%) sepia(5%) saturate(20%) hue-rotate(343deg) brightness(95%) contrast(81%);
}

.section__image_active {
  filter: brightness(0) saturate(100%) invert(41%) sepia(14%) saturate(4712%) hue-rotate(193deg) brightness(105%) contrast(106%);
}

.section__title {
  margin: 0;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: -0.01em;
  color: #858585;
  transition: all .2s linear;
}

.section__title_active {
  color: #317BFF;
}

.fillter__categories-container {
  max-height: 0;
  overflow: hidden;
  transition: all .5s linear;
}

.fillter__categories-container_active {
  max-height: 500px;
}

.fillter__categories {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow: hidden;
}

.fillter__load-more {
  padding: 0;
  border: 0;
  background-color: transparent;
  width: fit-content;
  cursor: pointer;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #317bff;
}

.fillter__category {
  border: 1px solid #d8d8d8;
  border-radius: 14px;
  padding: 10px;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #858585;
  background-color: transparent;
  transition: all .2s linear;
  box-sizing: border-box;
  cursor: pointer;
}

.fillter__category_active {
  border: 1px solid #317bff;
  background-color: #317bff;
  color: white;
}

.fillter__footer {
  border-top: 1px solid #d8d8d8;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.fillter__clear-button {
  background-color: transparent;
  border: 1px solid #d8d8d8;
  border-radius: 14px;
  padding: 10px;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #858585;
  cursor: pointer;
}

.fillter__apply-button {
  border-radius: 14px;
  border: 0;
  padding: 10px;
  width: 85px;
  height: 35px;
  background: #317bff;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.cards {
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 365px;
  gap: 16px;
}

.card {
  padding: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all .2s linear;
}

.card:hover {
  transform: scale(1.02);
}

.card__type {
  display: flex;
  gap: 10px;

}

.cards__tags {
  display: flex;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  height: 20px;
}

.card__tag {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  font-size: 10px;
  color: #000;
  background-color: #e4e4e4;
  border-radius: 10px;
  height: 20px;
  width: fit-content;
  box-sizing: border-box;
}

.card__section {
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #317BFF;
  border-radius: 50%;
}

.card__section-icon {
  width: 12px;
  height: 12px;
  filter: brightness(0) saturate(100%) invert(99%) sepia(88%) saturate(446%) hue-rotate(246deg) brightness(123%) contrast(100%);
}

.card__category {
  margin: 0 0 4px;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  color: #868686;
}

.card__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card__image {
  z-index: 1;
  margin: 4px 0;
  width: 100%;
  flex-grow: 1; /* Заставляет изображение занимать оставшееся пространство */
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.card__title {
  margin: 4px 0;
  max-width: 227px;
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.card__text {
  margin: 4px 0;
  font-weight: 500;
  max-width: 227px;
  font-size: 11px;
  text-align: justify;
  color: #858585;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0; /* Запрещает сжимать текст */
}

.card__image-container {
  position: relative;
  display: flex;
  height: 100%;
}

.card__date {
  padding: 3px 6px;
  margin: 0;
  z-index: 2;
  position: absolute;
  top: 10px;
  right: 6px;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.23);
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  border-radius: 7px;
  box-sizing: border-box;
}

.card__time {
  padding: 3px 6px;
  margin: 0;
  z-index: 2;
  position: absolute;
  top: 34px;
  right: 6px;
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.23);
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  border-radius: 7px;
  box-sizing: border-box;
}

.card__flag {
  margin: 0;
  z-index: 2;
  position: absolute;
  width: 30px;
  top: 10px;
  right: 6px;
  border-radius: 5px;
}

@media (max-width: 450px) {
  .search {
    max-width: 100%;
    min-width: 100%;
    justify-content: space-between;
  }

  .fillter {
    width: 100%;
  }

  .search__input {
    font-size: 16px;
    width: 180px;
  }

  .card-list {
    width: 100%;
  }

  .cards {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .card {
    width: 100%;
    box-sizing: border-box;
  }

  .card__type {
    height: 50px;
  }

  .cards__tags {
    height: 28px;
  }

  .card__tag {
    padding: 14px 16px;
    border-radius: 20px;
    font-size: 14px;
  }

  .card__section {
    min-width: 50px;
  }

  .card__section-icon {
    width: 20px;
    height: 20px;
  }

  .card__category {
    font-size: 15px;
  }

  .card__title {
    font-size: 19px;
    max-width: 100%;
  }

  .card__text {
    max-width: 100%;
    font-size: 14px;
  }
}