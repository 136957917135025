.panel {
  margin-top: 95px;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  position: relative;
  display: flex;
}

.panel__image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 18px;
  top: -75px;
  border: 2px solid white;
}

.panel__container {
  margin: 30px 18px 30px 204px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.panel__info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 500px;
}

.panel__title {
  width: 100%;
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  color: #000;
}

.panel__subtitle {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #797979;
  display: flex;
  align-items: center;
  gap: 5px;
}

.panel__subtitle-image {
  width: 16px;
  height: 17px;
}

.panel__row {
  display: flex;
  gap: 10px;
}

@media (max-width: 450px) {
  .panel {
    
  }

  .panel__row {
    width: 100%;
    justify-content: center;
  }

  .panel__image {
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
  }

  .panel__container {
    margin: 100px 0 20px 0;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .panel__info {
    width: 100%;
  }

  .panel__title {
    text-align: center;
  }
}