.auth {
  height: 100vh;
  width: 100vw;
  position: relative;
  background: linear-gradient(225deg, #c7dbff 14.17%, #317bff 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 143px;
}

.auth__left-rect {
  z-index: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.auth__right-rect {
  z-index: 0;
  position: absolute;
  right: 0;
  bottom: 0;
}

.auth__form {
  z-index: 1;
  width: 505px;
  box-sizing: border-box;
  padding: 56px;
  border-radius: 32px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.auth__title {
  margin: 0;
  font-weight: 500;
  font-size: 36px;
  color: #202020;
  text-align: center;
}

.auth__text {
  margin: 12px 0 0;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #84818a;
}

.auth__inputs {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.auth__inputs-container {
  display: flex;
  gap: 24px;
}

.auth__checkbox {
  margin: 13px 0 11px;
}

.auth__submit-button {
  margin-top: 13px;
  width: 100%;
}

.auth__or {
  margin: 16px 0;
  display: flex;
  align-items: center;
  height: 20px;
}

.auth__line {
  width: 100%;
  height: 1px;
  background-color: #dcdbdd;
}

.auth__or-title {
  margin: 0;
  padding: 0px 10px;
  height: 17px;
  font-weight: 400;
  font-size: 12px;
  line-height: 142%;
  color: #84818a;
}

.auth__button {
  background-color: transparent;
  border: 1px solid #dcdbdd;
  padding: 14px 16px;
  width: 100%;
  height: 49px;
  font-weight: 500;
  font-size: 14px;
  color: #4285f4;
  gap: 12px;
}

.auth__link-text {
  margin: 24px 0 0;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #0a0a0a;
}

.auth__link {
  text-decoration: none;
  color: #4285f4;
}