.popup__container_type_big {
  width: 846px;
}

.add {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.add__title {
  margin: 0;
  width: fit-content;
  font-weight: 500;
  font-size: 20px;
  color: #000;
}

.add__form {
  width: 100%;
  display: flex;

}

.add__column {
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  gap: 13px;
}