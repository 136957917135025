.sidebar {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  min-width: 284px;
  height: 100dvh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all .2s linear;
  position:fixed;
}

.sidebar__header {
  padding: 21px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
}

.sidebar__close-button {
  padding: 0;
  background-image: url(../../images/Sidebar/Close-button.svg);
  background-color: transparent;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;

}

.sidebar__navigation {
  margin: 20px 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.user {
  width: 100%;
  border: 1px solid #E6E7EC;
  border-radius: 20px;
  cursor: pointer;
}

.user__container {
  padding: 16px;
  display: flex;
  gap: 16px;
}

.user__image {
  min-width: 40px;
  width: 40px;
  height: 40px;
  object-fit:cover;
  object-position: center;
  border-radius: 50%;
}

.user__info {
  max-width: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 2px;
  justify-content: center;
}

.user__title {
  height: 17px;
  width: 100%;
  overflow: hidden;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user__text {
  width: 100%;
  height: 14px;
  overflow: hidden;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: #858585;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.links {
  padding: 0;
  margin: 0;
  width: 100%;
}

.link {
  height: 45px;
  display: flex;
  padding: 0;
  list-style: none;
}

.link__container {
  width: 100%;
  padding: 14px 16px;
  display: flex;
  gap: 10px;
  text-decoration: none;
  box-sizing: border-box;
  border-radius: 12px;
  /* transition: all .2s linear; */
}

.link__container:hover {
  background-color: #F0F0F0;
}

.link__container_active {
  border: 1px solid #E6E7EC;
  cursor: default;
}

.link__container_active:hover {
  background-color: transparent;
}

.link__image {
  width: 15px;
  height: 15px;
  transition: all .2s linear;
}

.link__image_active {
  filter: brightness(0) saturate(100%) invert(35%) sepia(56%) saturate(2497%) hue-rotate(209deg) brightness(104%) contrast(101%);
}

.link__title {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #000;
  transition: all .2s linear;
}

.link__title_active {
  font-weight: 700;
  color: #317BFF;
}

.sidebar__title {
  margin: 8px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 129%;
  color: #858585;
}

.sidebar__footer {
  border-top: 1px solid #d8d8d8;
}

@media (min-width: 1441px) {
  .sidebar {
    margin: 10px 0;
    border-radius: 20px;
    height: calc(100dvh - 20px);
  }
}

@media (max-width: 450px) {
  .sidebar_hidden {
    display: none;
  }

  .sidebar {
    z-index: 5;
    width: 100%;
    border-radius: 0;
    overflow-y: scroll;
  }

  .user__image {
    width: 60px;
    height: 60px;
  }

  .user__title {
    font-size: 18px;
    height: 20px;
  }

  .user__text {
    font-size: 16px;
    height: 20px;
  }

  .sidebar__title {
    font-size: 16px;
  }

  .link {
    height: 50px;
  }

  .link__title {
    font-size: 18px;
  }

  .link__image {
    width: 20px;
    height: 20px;
  }
}

