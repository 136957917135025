.user-nav {
  margin: 20px 0;
  width: 100%;
  display: flex;
  gap: 20px;
  height: 392px;
}

.user-nav__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 360px;
  height: 100%;
}

.wish {
  width: 75px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border-radius: 14px;
  transition: all .2s linear;
}

.wish:hover {
  background-color: #F0F0F0;
}

.wish__title {
  text-align: center;
  width: 100%;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  border-radius: 14px;
  color: #000;
}

.wish__container {
  width: 70px;
  height: 70px;
  border: 1px solid #317bff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  transition: all .2s linear;
}

.wish__image {
  width: 30px;
  height: 30px;
  filter: brightness(0) saturate(100%) invert(37%) sepia(20%) saturate(4953%) hue-rotate(203deg) brightness(106%) contrast(101%);
}

.wish__counter {
  margin: 0;
  position: absolute;
  top: -3px;
  right: -3px;
  background-color: #317bff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}

.pal {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 75px;
  height: fit-content;
  text-decoration: none;
  border-radius: 14px;
  cursor: pointer;
  transition: all .2s linear;
}

.pal:hover {
  background-color: #F0F0F0;
}

.pal__image {
  width: 70px;
  height: 70px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.pal__title {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  color: #000;
  text-align: center;
}

.sub {
  padding: 5px;
  width: 100%;
  height: 70px;
  display: flex;
  gap: 10px;
  text-decoration: none;
  border-radius: 14px;
  transition: all .2s linear;
}

.sub:hover {
  background-color: #F0F0F0;
}

.sub__image {
  width: 70px;
  height: 70px;
  border: 1px solid #f0f0f0;
  border-radius: 50%;
  box-sizing: border-box;
}

.sub__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}

.sub__title {
  width: 100%;
  height: fit-content;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sub__description {
  width: 100%;
  height: fit-content;
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  color: #797979;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 450px) {
  .user-nav {
    flex-direction: column;
    height: fit-content;
    width: 100%;
  }

  .user-nav__container {
    width: 100%
  }

  .wish {
    width: 65px;
  }

  .wish__container {
    width: 65px;
    height: 65px;
  }

  .pal {
    width: 65px;
  }

  .pal__image {
    width: 65px;
    height: 65px;
  }

  .sub {
    flex-direction: column;
    width: 65px;
    box-sizing: border-box;
    padding: 0;
  }

  .sub__image {
    width: 65px;
    height: 65px;
  }

  .sub__container {
    display: none;
  }
}