.brands {
  width: 100%;
}

.brands__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 265px;
  gap: 24px 16px;
}

@media (max-width: 450px) {
  .brands {
    position: relative;
    order: 1;
    display: flex;
    height: 255px;
  }

  .brands__container::-webkit-scrollbar {
    display: none;
  }

  .brands__container {
    padding: 0 18px;
    box-sizing: border-box;
    width: 100vw;
    position: absolute;
    left: -18px;
    display: flex;
    overflow-x: scroll;
    flex-direction: row;
    gap: 20px;
  }
}